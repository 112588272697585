import { useRef, useContext, useCallback, useEffect, useState } from 'react';
import SocketContext from './SocketContext';
import { v4 as uuidv4 } from 'uuid';

export const useDataProvider = () => {
    const [requestData, setRequestData] = useState();
    const { sendJsonMessage, lastJsonMessage } = useContext(SocketContext);
    const senderId = useRef(null);
    const subscribedRef = useRef(false);

    useEffect(() => {
        let mounted = true;
        if (lastJsonMessage && lastJsonMessage?.client === senderId.current && mounted) {
            if (lastJsonMessage.code !== 200) {
                console.error(lastJsonMessage.data)
            }
            else {
                setRequestData(lastJsonMessage.data);
            }
        }
        return () => {
            mounted = false;
        };
    }, [lastJsonMessage]);

    const getData = useCallback((target, params = {}, subscribe = false) => {
        if (!target) {
            throw new Error('Invalid function call');
        }
        const client = uuidv4();
        senderId.current = client;
        sendJsonMessage({
            target: target,
            params: { ...params, client: client },
            subscribe: subscribe
        });
        if (subscribe && !subscribedRef.current) {
            subscribedRef.current = true;
        }
    }, [sendJsonMessage]);

    const doUnsubscribe = useCallback(() => {
        if (!subscribedRef.current || !senderId.current) {
            return;
        }
        sendJsonMessage({
            target: 'unsubscribe',
            params: {
                client: senderId.current
            }
        });
    }, [sendJsonMessage]);

    return { getData, data: requestData, doUnsubscribe };
};
