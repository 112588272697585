import * as React from 'react';
import SocketContext from './SocketContext';
import useWebSocket from 'react-use-websocket';

export default function SocketProvider({ children, token }) {
    const ws = useWebSocket(`${process.env.REACT_APP_WS_HOST}?${token}`, {
        shouldReconnect: (closeEvent) => {
            return true;
        },
        reconnectInterval: 5000,
        retryOnError: true,
        reconnectAttempts: 20
    });

    return (
        <SocketContext.Provider value={ws}>
            {children}
        </SocketContext.Provider>
    );
}