import './App.css';
import * as React from 'react';
/* import Dashboard from './Views/Dashboard'; */
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { Routes, Route } from "react-router-dom";
import ListItems from '../src/Components/listItems';
/* import { fiFI, enUS } from '@mui/material/locale'; */
/* import UsersView from './Views/DataLogs/Users/UsersView'
import DevicesView from './Views/DataLogs/Devices/DevicesViews'; */
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
/* import FinFlag from '../src/Assets/Images/FinIcon.svg';
import UsaFlag from '../src/Assets/Images/UsaIcon.svg';
import Icon from '@mui/material/Icon'; */
/* import ProgramsView from './Views/DataLogs/Programs/ProgramsView'; */
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
/* import UserPage from './Views/DataLogs/Users/UserPage';
import DevicePage from './Views/DataLogs/Devices/DevicePage';
import ProgramPage from './Views/DataLogs/Programs/ProgramPage';
import { UsersData, DevData, ProgramData, UserColumns, DevColumns, ProgColumns } from './Data/TestData'; */
import DeviceView from './Views/Devices/DeviceView';
import Login from './Views/Login';
import SocketProvider from './Components/Backend/SocketProvider';

var userLanguage = navigator.language;

const translationsEn = {
    dashboard: "Dashboard", usage: "Usage", occupancy: "Occupancy", users: "Users", devices: "Devices",
    healthPrograms: "Health programs", name: "Name", topHealthPrograms: "Top Health Programs", top5usersbyusage: "Top 5 Users by Usage", firstname: "First Name", lastname: "Last Name",
    averageUsage: "Average Usage", topDevicesUsedbyUser: "Top Devices used by User", topDevicesUsedbyUsers: "Top Devices used by Users", topUserBy: "Top Users Used by", week: 'Week', month: "Month", year: "Year",
    login: "Login", username: "Username", password: "Password", topDevicesUsed: "Top Devices used by", top5UsersUsedBy: 'Top 5 Users used by', daily: "Daily", groupname: "Group Name",
    address: "Address", description: "Description", timeframe: "Timeframe", day: "Day", deviceUsage: "Device Usage"
};
const translationsFi = {
    dashboard: "Etusivu", usage: "Käyttö", occupancy: "Käyttöaste", users: "Käyttäjät", devices: "Laitteet",
    healthPrograms: "Hoito-ohjelmat", name: "Nimi", topHealthPrograms: "Suosituimmat Hoito-ohjelmat", top5usersbyusage: "Top 5 käyttäjät käytön mukaan", firstname: "Etunimi", lastname: "Sukunimi",
    averageUsage: "Keskimääräinen käyttö", topDevicesUsed: "Suosituimmat laitteet", topDevicesUsedbyUsers: "Suosituimmat käyttäjien käyttämät laitteet", topUserBy: "Suosituimmat käyttäjät",
    week: 'Viikko', month: "Kuukausi", year: "Vuosi", login: "Kirjaudu sisään", username: "Käyttäjänimi", password: "Salasana", top5UsersUsedBy: 'Suosituimmat käyttäjät', daily: "Tänään", groupname: "Ryhmän nimi",
    address: "Osoite", description: "Kuvaus", timeframe: "Aikaväli", day: "Päivä", deviceUsage: "Laitteen käyttö"
};
const translationsSwe = {};

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: translationsEn },
        fi: { translation: translationsFi },
        swe: { translation: translationsSwe },
    },
    // eslint-disable-next-line eqeqeq
    lng: userLanguage == "fi" ? "fi" : "en",
    // eslint-disable-next-line eqeqeq
    fallbackLng: userLanguage == "fi" ? "fi" : "en",
    interpolation: { escapeValue: false },
});

/* const FinLogo = () => (
    <Icon>
        <img src={FinFlag} height={25} width={25} alt="Fin" />
    </Icon>
);

const UsaLogo = () => (
    <Icon>
        <img src={UsaFlag} height={25} width={25} alt="Usa" />
    </Icon>
); */

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

function App() {
    const [token, setToken] = React.useState(localStorage.getItem('token'));
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = React.useState(prefersDarkMode ? 'dark' : 'light');
    // eslint-disable-next-line eqeqeq
    /* const [locale, setLocale] = React.useState(userLanguage == 'fi' ? fiFI : enUS);
    const toggleLocale = () => {
        if (locale === fiFI) {
            setLocale(enUS);
            i18n.changeLanguage("en");

        }
        else {
            setLocale(fiFI);
            i18n.changeLanguage("fi");
        }
    }; */

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: mode ? 'dark' : 'light',
                    primary: {
                        main: "#235580"
                    },

                },
            },
                /* locale */
            ),
        [mode/* , locale */],
    );
/* 
    React.useEffect(() => {
        if (locale === fiFI) {
            i18n.changeLanguage("fi");

        }
        else {
            i18n.changeLanguage("en");
        }
    }, [locale]); */

    const handleLogout = () => {
        setToken(null);
        setAnchorEl(null);
        localStorage.removeItem('token');
        /* window.location.reload(); */
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        !token ?
            <Login setToken={(res) => setToken(res)} theme={theme} t={t} /> :
            <SocketProvider token={token}>
                <ThemeProvider theme={theme}>
                    <Box sx={{ display: 'flex' }}>
                        <CssBaseline />
                        <AppBar position="absolute" open={open}>
                            <Toolbar
                                sx={{
                                    pr: '24px', // keep right padding when drawer closed
                                }}
                            >
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={toggleDrawer}
                                    sx={{
                                        marginRight: '36px',
                                        ...(open && { display: 'none' }),
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="inherit"
                                    noWrap
                                    sx={{ flexGrow: 1 }}
                                >
                                    {t('dashboard')}
                                </Typography>
                                {/* <Tooltip title={locale === fiFI ? 'Finnish' : 'English'} >
                                    <IconButton sx={{ ml: 1 }} onClick={toggleLocale} color="inherit">
                                        {locale === fiFI ? <FinLogo /> : <UsaLogo />}
                                    </IconButton>
                                </Tooltip> */}
                                <Tooltip title={theme.palette.mode === 'dark' ? 'Light Mode' : 'Dark Mode'}>
                                    <IconButton sx={{ ml: 1 }} onClick={() => setMode(!mode)} color="inherit">
                                        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                                    </IconButton>
                                </Tooltip>
                                {/*             <IconButton color="inherit">
                <Badge badgeContent={"1"} color="secondary">
                  <AccountCircleIcon />
                </Badge>
              </IconButton> */}
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                    edge="end"
                                >
                                    <AccountCircleIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </Toolbar>
                        </AppBar>
                        <Drawer variant="permanent" open={open}>
                            <Toolbar
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    px: [1],
                                }}
                            >
                                <Typography variant="h6" textAlign={"center"} sx={{ width: "100%", ml: 3}}>MedSine</Typography>
                                <IconButton onClick={toggleDrawer}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </Toolbar>
                            <Divider />
                            <ListItems t={t} />
                        </Drawer>
                        <Routes>
                            {/* <Route path="" element={<Dashboard t={t} />} /> */}
                            <Route path="device" >
                                <Route path="" element={<DeviceView t={t} />} />
                                <Route path=":userId" element={<div />} />
                            </Route>
{/*                             <Route path="kayttajat" >
                                <Route path="" element={<UsersView t={t} data={UsersData} columns={UserColumns} />} />
                                <Route path=":userId" element={<UserPage t={t} data={UsersData} columns={UserColumns} />} />
                            </Route>
                            <Route path="laitteet">
                                <Route path="" element={<DevicesView t={t} data={DevData} columns={DevColumns} />} />
                                <Route path=":devId" element={<DevicePage t={t} xKey="firstName" data={DevData} columns={DevColumns} />} />
                            </Route>
                            <Route path="/ohjelmat" >
                                <Route path="" element={<ProgramsView t={t} data={ProgramData} columns={ProgColumns} />} />
                                <Route path=":progId" element={<ProgramPage t={t} data={ProgramData} columns={ProgColumns} />} />

                            </Route> */}
                        </Routes>
                    </Box>
                </ThemeProvider>
            </SocketProvider>
    );
}

export default App;


/*<React.Suspense fallback="Loading...">  Shows Loading... while content is loading */
/* </React.Suspense> */