import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
/* import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import LoyaltyIcon from '@mui/icons-material/Loyalty'; */
import List from '@mui/material/List';
/* import Divider from '@mui/material/Divider'; */
import { Link } from "react-router-dom";
import EventSeatIcon from '@mui/icons-material/EventSeat';

export default function ListItems({ t }) {
  return (
    <>
{/*       <List>
        <div>
          <ListItem button component={Link} to="">
            <ListItemIcon style={{ color: "blue" }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={t("dashboard")} />
          </ListItem>
        </div>
      </List>
      <Divider /> */}
      <List>
        <div>
          <ListSubheader inset sx={{ fontSize: 18, textAlign: "left" }}>Devices</ListSubheader>
          <ListItem button component={Link} to="/device" sx={{ mb: 5 }}>
            <ListItemIcon style={{ color: "#808080" }}>
              <EventSeatIcon />
            </ListItemIcon>
            <ListItemText primary={t("Device 1")} />
          </ListItem>
{/*           <ListSubheader inset sx={{ fontSize: 18, textAlign: "left" }}>Data logs</ListSubheader>
          <ListItem button component={Link} to="/kayttajat" >
            <ListItemIcon style={{ color: "green" }}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={t("users")} />
          </ListItem>
          <ListItem button component={Link} to="/laitteet">
            <ListItemIcon style={{}} >
              <PermDeviceInformationIcon />
            </ListItemIcon>
            <ListItemText primary={t("devices")} />
          </ListItem>
          <ListItem button component={Link} to="/ohjelmat">
            <ListItemIcon style={{ color: "red" }}>
              <LoyaltyIcon />
            </ListItemIcon>
            <ListItemText primary={t("healthPrograms")} />
          </ListItem> */}
        </div>
      </List>
    </>
  )
}