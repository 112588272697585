
import AddIcon from '@mui/icons-material/Add';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RemoveIcon from '@mui/icons-material/Remove';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { default as VolumeUp, default as VolumeUpIcon } from '@mui/icons-material/VolumeUp';
import { Autocomplete, Avatar, Box, Button, Divider, Grid, IconButton, Modal, Slider, Stack, styled, TextField, ToggleButton, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDataProvider } from '../../Components/Backend/DataProvider';
import ActivityGraph from './ActivityGraph';

const style = {
    bgcolor: 'background.paper',
    marginTop: 10,
    marginBottom: 2.5,
    borderRadius: 4,
    p: 4,
    paddingTop: 4,
    height: "-webkit-fill-available",
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
        width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        marginTop: 1.5,
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: "#1C4E71",
    },
    '&::-webkit-scrollbar-corner': {
        display: "none"
    },
    width: "100%",
    overflowX: "hidden",
    position: "relative"
};

const itemStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "100%"
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    height: "75%",
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 3,
    borderRadius: 4,
};

const MuiToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        /* color: "white",
        backgroundColor: '#235580' */
    }
});


export default React.memo(function DeviceView({ t }) {
    const [muted, setMuted] = useState(false);
    const [currentDevice, setCurrentDevice] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState();
    const { getData: controlDevice } = useDataProvider();
    const { getData: getDeviceList, data: deviceList = [] } = useDataProvider();
    const { getData: getDeviceState, data: deviceState, doUnsubscribe } = useDataProvider();
    const { getData: getTreatmentList, data: treatmentList = [] } = useDataProvider();

    const currentState = useMemo(() => {
        if (currentDevice && deviceState[currentDevice.id] && !deviceState[currentDevice.id].stale) {
            return deviceState[currentDevice.id];
        }
        return null;
    }, [currentDevice, deviceState]);

    useEffect(() => {
        getDeviceList('deviceList');
        getDeviceState('deviceStatus', {}, true);
        getTreatmentList('treatmentList');

        return () => doUnsubscribe();
    }, [doUnsubscribe, getDeviceList, getDeviceState, getTreatmentList]);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [legsPower, setLegsPower] = useState(1);
    const [thighsPower, setThighsPower] = useState(1);
    const [backPower, setBackPower] = useState(1);
    const [neckPower, setNeckPower] = useState(1);
    const [volControlEnabled, setVolControlEnabled] = useState(false);
    const [stopped, setStopped] = useState(true);

    useEffect(() => {
        if (currentState) {
            if (currentState.status.quad_status) {
                setVolControlEnabled(true);
                setLegsPower(currentState.status.quad_status.legs * 10);
                setThighsPower(currentState.status.quad_status.thighs * 10);
                setBackPower(currentState.status.quad_status.back * 10);
                setNeckPower(currentState.status.quad_status.neck * 10);
            }
            setMuted(currentState?.status.devices[0].is_muted);
            setPower(Math.floor(currentState.status.devices[0].multiplier * 100));
            setStopped(currentState.status.stopped);
        }
    }, [currentState]);

    /* Console logissa helposti näkyvissä tiedot :) */
/*     console.log(
        " Filename:", currentState?.status.filename,
        '\n',
        "Paused:", currentState?.status.paused,
        '\n',
        "Plan current time:", currentState?.status.plan_current_time,
        '\n',
        "Is it Stopped?:", currentState?.status.stopped,
        '\n',
        "Power 1:", currentState?.status.devices[0].volume_left * 100 + "%",
        '\n',
        "Power 2:", currentState?.status.devices[0].volume_right * 100 + "%",
        '\n',
        "PowerMultiplier:", currentState?.status.devices[0].multiplier * 100 + "%",
        '\n',
        "Is it playing?:", currentState?.status.devices[0].playing,
        '\n',
        "Is it muted?:", currentState?.status.devices[0].is_muted,
        '\n',
    ); */

    const [power, setPower] = useState(100);

    const handleChange = (event, newValue) => {
        setPower(newValue);
    };

    const handleDeviceControl = useCallback((type, controlParam) => {
        if (!currentDevice) return;
        controlDevice('deviceControl', { device: currentDevice.id, type: type, controlParam: controlParam });
    }, [controlDevice, currentDevice]);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                px: 2
            }}
        >

            <Grid container maxWidth="2xl" sx={style}>
                <Autocomplete
                    disablePortal
                    id="deviceList-autocomplete"
                    getOptionLabel={(option) => option.name}
                    options={deviceList}
                    value={currentDevice}
                    onChange={(e, device) => setCurrentDevice(device)}
                    sx={{ width: 300, position: "absolute" }}
                    renderInput={(params) => <TextField {...params} label="Select Device" />}
                />
                <Tooltip title="Activity Graph">
                    <IconButton onClick={handleOpen} sx={{ position: "absolute", right: 20, top: 20 }}>
                        <BarChartIcon sx={{ width: 40, height: 40 }} />
                    </IconButton>
                </Tooltip>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <ActivityGraph />
                    </Box>
                </Modal>
                <Grid item xs={12}>
                    <Grid container sx={itemStyle}>
                        <Grid item xs={12} sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            height: "100%",
                        }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                    <Avatar sx={{ height: 200, width: 200, border: currentState ? "5px solid #7CFC00" : "5px solid #808080" }}>
                                        <EventSeatIcon sx={{ height: "75%", width: "75%", color: currentState ? currentState.status.devices[0].playing ? "#7CFC00" : "#235580" : "gray" }} />
                                    </Avatar>
                                    <Typography component={"div"} variant="h6" sx={{ color: currentState ? "#7CFC00" : "gray", fontWeight: "bold" }}>
                                        {currentState ? "Online" : "Offline"}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", ml: 5 }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", width: 250 }}>
                                        <Typography component={"div"} variant="h6" >
                                            Name:
                                        </Typography>
                                        <Typography component={"div"} variant="h6" >
                                            Device 1
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1, borderWidth: 2 }} />
                                    <Box sx={{ display: "flex", justifyContent: "space-between", width: 250 }}>
                                        <Typography component={"div"} variant="h6" >
                                            Status:
                                        </Typography>
                                        <Typography component={"div"} variant="h6" >
                                            {currentState ? currentState.status.stopped ? "Stopped" : currentState.status.paused ? "Paused" : <span style={{ color: "#7CFC00" }}>Playing </span> : "Inactive"}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {volControlEnabled && <Box sx={{ display: "flex", ml: 5 }}>
                                <Box sx={{ display: "flex", flexDirection: "column", position: "relative", }}>
                                    <Typography component={"div"} variant="h5" sx={{ position: "absolute", top: -30, left: -3 }}>
                                        Legs
                                    </Typography>
                                    <IconButton onClick={() => {
                                        if (legsPower < 10) {
                                            handleDeviceControl('quad_volume', {
                                                part: 'legs',
                                                volume: (legsPower + 1) / 10
                                            });
                                            setLegsPower(prev => prev + 1);
                                        }
                                    }}>
                                        <AddIcon sx={{ width: 30, height: 30 }} />
                                    </IconButton>
                                    <Typography component={"div"} variant="h5" textAlign="center" sx={{ border: "2px solid #235580", borderRadius: "50%", p: 1 }}>
                                        {legsPower}
                                    </Typography>
                                    <IconButton onClick={() => {
                                        if (legsPower > 0) {
                                            handleDeviceControl('quad_volume', {
                                                part: 'legs',
                                                volume: (legsPower - 1) / 10
                                            });
                                            setLegsPower(prev => prev - 1);
                                        }
                                    }}>
                                        <RemoveIcon sx={{ width: 30, height: 30 }} />
                                    </IconButton>
                                </Box>

                                <Box sx={{ display: "flex", flexDirection: "column", position: "relative", ml: 5 }}>
                                    <Typography component={"div"} variant="h5" textAlign="center" sx={{ position: "absolute", top: -30, left: -15 }}>
                                        Thighs
                                    </Typography>
                                    <IconButton onClick={() => {
                                        if (thighsPower < 10) {
                                            handleDeviceControl('quad_volume', {
                                                part: 'thighs',
                                                volume: (thighsPower + 1) / 10
                                            });
                                            setThighsPower(prev => prev + 1);
                                        }
                                    }}>
                                        <AddIcon sx={{ width: 30, height: 30 }} />
                                    </IconButton>
                                    <Typography component={"div"} variant="h5" textAlign="center" sx={{ border: "2px solid #235580", borderRadius: "50%", p: 1 }}>
                                        {thighsPower}
                                    </Typography>
                                    <IconButton onClick={() => {
                                        if (thighsPower > 0) {
                                            handleDeviceControl('quad_volume', {
                                                part: 'thighs',
                                                volume: (thighsPower - 1) / 10
                                            });
                                            setThighsPower(prev => prev - 1);
                                        }
                                    }}>
                                        <RemoveIcon sx={{ width: 30, height: 30 }} />
                                    </IconButton>
                                </Box>

                                <Box sx={{ display: "flex", flexDirection: "column", position: "relative", ml: 5 }}>
                                    <Typography component={"div"} variant="h5" sx={{ position: "absolute", top: -30, left: -3 }}>
                                        Back
                                    </Typography>
                                    <IconButton onClick={() => {
                                        if (backPower < 10) {
                                            handleDeviceControl('quad_volume', {
                                                part: 'back',
                                                volume: (backPower + 1) / 10
                                            });
                                            setBackPower(prev => prev + 1);
                                        }
                                    }}>
                                        <AddIcon sx={{ width: 30, height: 30 }} />
                                    </IconButton>
                                    <Typography component={"div"} variant="h5" textAlign="center" sx={{ border: "2px solid #235580", borderRadius: "50%", p: 1 }}>
                                        {backPower}
                                    </Typography>
                                    <IconButton onClick={() => {
                                        if (backPower > 0) {
                                            handleDeviceControl('quad_volume', {
                                                part: 'back',
                                                volume: (backPower - 1) / 10
                                            });
                                            setBackPower(prev => prev - 1);
                                        }
                                    }}>
                                        <RemoveIcon sx={{ width: 30, height: 30 }} />
                                    </IconButton>
                                </Box>

                                <Box sx={{ display: "flex", flexDirection: "column", position: "relative", ml: 5 }}>
                                    <Typography component={"div"} variant="h5" sx={{ position: "absolute", top: -30, left: -3 }}>
                                        Neck
                                    </Typography>
                                    <IconButton onClick={() => {
                                        if (neckPower < 10) {
                                            handleDeviceControl('quad_volume', {
                                                part: 'neck',
                                                volume: (neckPower + 1) / 10
                                            });
                                            setNeckPower(prev => prev + 1);
                                        }
                                    }}>
                                        <AddIcon sx={{ width: 30, height: 30 }} />
                                    </IconButton>
                                    <Typography component={"div"} variant="h5" textAlign="center" sx={{ border: "2px solid #235580", borderRadius: "50%", p: 1 }}>
                                        {neckPower}
                                    </Typography>
                                    <IconButton onClick={() => {
                                        if (neckPower > 0) {
                                            handleDeviceControl('quad_volume', {
                                                part: 'neck',
                                                volume: (neckPower - 1) / 10
                                            });
                                            setNeckPower(prev => prev - 1);
                                        }
                                    }}>
                                        <RemoveIcon sx={{ width: 30, height: 30 }} />
                                    </IconButton>
                                </Box>
                            </Box>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ position: "relative", bottom: 40 }}>
                    <Grid container sx={itemStyle}>
                        <Grid item xs={5} sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            flexDirection: "column",
                            border: "2px solid gray",
                            borderRadius: 4,
                            position: "relative",
                        }}>
                            <Box sx={{ flex: 0.5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%", px: 5, pt: 2 }}>
                                <Typography component={"div"} variant="h4">
                                    Programs
                                </Typography>
                                <Autocomplete
                                    options={treatmentList}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(_, newVal) => setSelectedPlan(newVal)}
                                    renderInput={(params) => <TextField {...params} label="Plans" />}
                                    fullWidth
                                    sx={{ position: "relative", top: 15 }}
                                />
                            </Box>
                            <Box sx={{ flex: 0.5, display: "flex", justifyContent: "space-evenly", width: "100%" }}>

                                <IconButton
                                    variant="contained"
                                    onClick={() => {
                                        setMuted(prev => !prev);
                                        handleDeviceControl('mute');
                                    }}
                                    sx={{ width: 60, height: 60, borderRadius: "50%", backgroundColor: "#235580", "&:hover": { backgroundColor: "#235580" }, position: "absolute", top: 10, right: 10 }}
                                >
                                    {muted
                                        ?
                                        <VolumeOffIcon sx={{ color: "#FFFFFF" }} />
                                        :
                                        <VolumeUpIcon sx={{ color: "#FFFFFF" }} />
                                    }
                                </IconButton>

                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        if (selectedPlan) handleDeviceControl('file', selectedPlan.filename);
                                    }}
                                    sx={{ height: 75 }}>
                                    Change plan
                                </Button>

                                <MuiToggleButton
                                    value="check"
                                    selected={stopped}
                                    onChange={() => {
                                        if (selectedPlan) {
                                            handleDeviceControl('play');
                                        }
                                    }}
                                    sx={{ width: 75, height: 75, borderRadius: "50%", backgroundColor: "#235580", "&:hover": { backgroundColor: "#235580" } }}
                                >
                                    {stopped
                                        ?
                                        <PlayArrowIcon sx={{ height: 40, width: 40 }} />
                                        :
                                        <StopIcon sx={{ height: 40, width: 40, }} />
                                    }
                                </MuiToggleButton>

                                {/* <Button onClick={() => handleDeviceControl('stop')} variant="contained" sx={{ width: 75, height: 75, backgroundColor: "red", borderRadius: "50%", "&:hover": { backgroundColor: "#FF000099" } }}>
                                    Stop
                                </Button> */}
                            </Box>
                        </Grid>
                        <Grid item xs={5} sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            flexDirection: "column",
                            border: "2px solid gray",
                            borderRadius: 4
                        }}>
                            <Box sx={{ flex: 0.5, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography component={"div"} variant="h4">
                                    Power {power}%
                                </Typography>
                            </Box>
                            <Box sx={{ flex: 0.5, display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
                                <Stack spacing={2} direction="row" sx={{ width: "50%" }}>
                                    <VolumeDown />
                                    <Slider aria-label="Power" value={power} onChange={handleChange} />
                                    <VolumeUp />
                                </Stack>
                                <Button sx={{ width: 80, mt: 2 }} variant="contained" onClick={() => handleDeviceControl('multiplier', power > 0 ? power / 100 : 0)}>Apply</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
});
